import { randomBytes } from "crypto";
import { box } from "tweetnacl";

const newNonce = () => randomBytes(box.nonceLength);

export interface Message {
	content?: Uint8Array;
	nonce?: Uint8Array;
	key?: Uint8Array;
	err?: string;
}

export class Enc {
	myPublicKey: Uint8Array;
	theirPublicKey: Uint8Array;
	secretKey: Uint8Array;

	constructor() {
		const { publicKey, secretKey } = box.keyPair();

		this.myPublicKey = publicKey;
		this.secretKey = secretKey;
	}

	setTheirPublicKey(key: Uint8Array) {
		this.theirPublicKey = new Uint8Array(key);
	}

	encrypt(message: string): Message {
		let msg = Buffer.from(message, "utf-8");
		let nonce = newNonce();
		let out = box(msg, nonce, this.theirPublicKey, this.secretKey);

		return { content: out, nonce: nonce };
	}

	decrypt(message: Message) {
		let out = box.open(
			new Uint8Array(message.content!),
			new Uint8Array(message.nonce!),
			this.theirPublicKey,
			this.secretKey
		)!;

		return Buffer.from(out).toString("utf-8");
	}
}
