export class UI {
	inChat: boolean;
	intro: HTMLElement;
	messages: HTMLElement;
	idText: HTMLElement;
	footer: HTMLElement;
	error: HTMLElement;
	msgCont: HTMLElement;
	sendButton: HTMLElement;
	backButton: HTMLElement;
	idInput: HTMLInputElement;
	textInput: HTMLInputElement;

	constructor() {
		this.inChat = false;
		this.intro = document.querySelector(".intro")!;
		this.messages = document.querySelector(".messages")!;
		this.msgCont = document.querySelector(".message-cont")!;
		this.idText = document.querySelector("#id-text")!;
		this.idInput = document.querySelector("input.id")!;
		this.error = document.querySelector(".error")!;
		this.footer = document.querySelector(".footer")!;
		this.textInput = document.querySelector(".input input")!;
		this.sendButton = document.querySelector(".icon.send")!;
		this.backButton = document.querySelector(".icon.back")!;

		this.footer.addEventListener("click", () => {
			navigator.clipboard.writeText(this.idText.innerText);

			//@ts-ignore
			if (window.copyText) window.copyText(this.idText.innerText);
		});

		this.showIntro();
		this.hideMessages();
	}

	addMessage(text: string, self: boolean = false) {
		let el = document.createElement("div");

		if (self) el.classList.add("self");

		el.classList.add("message");
		el.innerHTML = text
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;");

		this.msgCont.appendChild(el);

		this.msgCont.scrollTop = this.msgCont.scrollHeight;
	}

	clearMessages() {
		this.msgCont.innerHTML = "";
	}

	showIntro() {
		this.intro.classList.remove("hidden");

		// @ts-ignore
		if (window.showIntro) window.showIntro();
	}

	hideIntro() {
		this.intro.classList.add("hidden");
	}

	showMessages() {
		this.messages.classList.remove("hidden");

		// @ts-ignore
		if (window.showMessages) window.showMessages();
	}

	hideMessages() {
		this.messages.classList.add("hidden");
	}

	showError(text: string = "Connection Error!") {
		this.error.children[0].innerHTML = text;
		this.error.classList.add("show");
	}

	hideError() {
		this.error.classList.remove("show");
	}

	onInput(f: any) {
		this.idInput.oninput = f;
		this.idInput.onpaste = f;
	}

	onMessage(f: any) {
		this.textInput.onkeyup = f;
		this.sendButton.onclick = f;
	}

	onBack(f: any) {
		this.backButton.onclick = f;

		//@ts-ignore
		window.onBack = f;
	}

	setSilentiumId(id: string) {
		this.idText.innerHTML = id;
	}
}
