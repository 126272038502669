import { DataConnection, Peer } from "peerjs";
import { randomBytes } from "crypto";
import { UI } from "./ui";
import { Enc, Message } from "./encryption";

const id = randomBytes(8).toString("hex").toUpperCase();
const enc = new Enc();
const ui = new UI();
const peer = new Peer(id);

let curConn = "";

function dataCallback(data: any) {
	if (typeof data == "object") {
		let msg: Message = data;

		if (msg.err) {
			ui.showError();
		}

		if (msg.key) {
			enc.setTheirPublicKey(msg.key);
		}

		if (msg.content && msg.nonce) {
			ui.addMessage(enc.decrypt(msg));
		}
	}
}

function sendText(conn: DataConnection, text: string) {
	let msg = enc.encrypt(text);

	ui.addMessage(text, true);

	conn.send(msg);

	ui.textInput.value = "";
}

function initConn(conn: DataConnection) {
	conn.on("data", dataCallback);

	conn.on("open", () => {
		if (ui.inChat) {
			conn.send({ err: "busy" });
			conn.close();
		}

		console.log("Connected:", conn.peer, "<=>", peer.id);

		conn.send({ key: enc.myPublicKey });

		curConn == conn.peer;

		ui.clearMessages();
		ui.showMessages();
		ui.hideIntro();
		ui.inChat = true;

		ui.idInput.value = "";

		ui.onBack((event: Event) => {
			ui.hideMessages();
			ui.showIntro();
			conn.close();
		});

		ui.onMessage((event: Event) => {
			let text = ui.textInput.value;

			if (text == "") return;

			if (event.type == "keyup") {
				//@ts-ignore
				if (event.key == "Enter") {
					sendText(conn, text);
				}

				return;
			}

			sendText(conn, text);
		});
	});

	conn.on("close", () => {
		ui.hideMessages();
		ui.showIntro();
		ui.inChat = false;
	});
}

function connectionError() {
	ui.idInput.value = "";
	ui.showError();
}

function IdInputCallback(event: Event) {
	let connId = ui.idInput.value;

	if (connId.length == 16) {
		if (connId == id) {
			connectionError();

			return;
		}

		console.log("Connecting to:", connId);

		let conn = peer.connect(connId);

		initConn(conn);
	}
}

peer.on("open", () => {
	ui.setSilentiumId(peer.id);
	ui.onInput(IdInputCallback);

	console.log("Started as:", peer.id);
});

peer.on("connection", (conn: DataConnection) => {
	console.log("Connection from:", conn.peer);

	initConn(conn);
});
